import axios from 'axios-jsonp-pro';

function parseUrl($url) {
  const $dom = document.createElement('a');
  $dom.href = $url;

  return {
    scheme: $dom.protocol.substr(0, $dom.protocol.indexOf(':')),
    host: $dom.hostname,
    port: $dom.port,
    path: $dom.pathname,
    query: $dom.search.substr($dom.search.indexOf('?') + 1),
    hash: $dom.hash.substr($dom.hash.indexOf('#')),
  };
}

export async function fetchArtworks(keyword, countryCode) {
  if (keyword.trim() === '') {
    return null;
  }

  const { results } = await axios.jsonp('https://itunes.apple.com/search', {
    params: {
      term: keyword || undefined,
      country: countryCode,
      media: 'music',
      entity: 'album',
      limit: 60
    },
  });

  return results.map((result) => {
    const parts = parseUrl(result.artworkUrl100.replace('100x100bb', '100000x100000-999'));

    const artwork = {
      standard: result.artworkUrl100.replace('100x100', '600x600'),
      hires: `http://is5.mzstatic.com${parts.path}`,
    };

    return {
      ...result,
      artwork,
    };
  });
}
