import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
// import './layout.css';
import '../styles/index.css';
import '../styles/main.css';

const Content = styled.div`
  ${tw`pb-8 font-sans text-black`};
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/css/flag-icon.min.css" />
        </Helmet>

        <Header siteTitle={data.site.siteMetadata.title} />

        <Content className="container">{children}</Content>
      </>
    )}
  />
);

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default Layout;
