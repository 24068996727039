// import { navigate } from 'gatsby';
import { store } from 'react-easy-state';

import { fetchArtworks } from './api';

const mainStore = store({
  keyword: '',
  countryCode: 'my',
  isLoading: false,
  artworks: null,
  queryString: null,

  async fetchArtworks() {
    mainStore.isLoading = true;
    mainStore.artworks = await fetchArtworks(mainStore.keyword, mainStore.countryCode);
    mainStore.isLoading = false;
  },
});

export default mainStore;
