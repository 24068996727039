import React from 'react';
import { Link, navigate } from 'gatsby';
import styled from '@emotion/styled';
import Select from 'react-select';
import { view } from 'react-easy-state';

import store from '../stores';

import logo from '../images/iaf-logo-light.svg';

import countries from '../countries.json';
import { colors } from '../../tailwind';

const countryOptions = Object.entries(countries).map(country => ({
  value: country[0],
  label: country[1],
}));

const Container = styled.div`
  ${tw`pt-8 pb-4 flex flex-wrap items-center`};
`;
const StyledLink = styled(Link)`
  ${tw`no-underline text-inherit inline-block mr-4`};
`;

class Header extends React.Component {
  state = {
    focused: false
  }

  submit = () => {
    if (store.keyword.length > 0) {
      navigate(`/search?q=${store.keyword}&c=${store.countryCode}`);
    }
  }

  onKeywordChange = (ev) => {
    store.keyword = ev.target.value;
  }

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      this.submit();
    }
  }

  onCountryChange = ({ value }) => {
    store.countryCode = value;
    navigate(`/search?q=${store.keyword}&c=${store.countryCode}`);
  }

  onFocus = () => {
    this.setState({ focused: true });
  }
  onBlur = () => {
    this.setState({ focused: false });
  }

  formatOptionLabel = ({ label, value }) => {
    return (
      <div css={tw`text-sm text-grey-darkest`}>
        <span className={`flag-icon flag-icon-${value}`} css={tw`mr-2`} />
        <span>{label}</span>
      </div>
    );
  }

  render() {
    const { focused } = this.state;

    return (
      <Container className="container">
        <StyledLink to="/">
          <img src={logo} alt="iTunes Artwork Finder" css={{ height: 30 }} />
          {/* <Title>iTunes Artwork Finder</Title> */}
        </StyledLink>
        <div css={tw`flex-1 relative`}>
          <span
            css={tw`absolute text-xs font-bold text-grey-dark`}
            style={{
              top: -16,
              opacity: focused ? 1 : 0,
              transition: 'opacity 0.1s ease'
            }}
          >
            Press enter to search
          </span>
          <input
            css={tw`
              bg-grey-lighter
              appearance-none
              border-2
              border-grey-lighter
              rounded
              w-full
              py-2
              px-3
              text-grey-darker
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-brand
            `}
            // style={{
            //   paddingRight: 80
            // }}
            type="search"
            placeholder="Search for artworks..."
            autoComplete="off"
            value={store.keyword}
            onChange={this.onKeywordChange}
            onKeyPress={this.onKeyPress}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <button
            type="button"
            css={tw`bg-brand text-white text-xs font-bold py-1 px-2 rounded uppercase absolute z-10 hover:bg-black`}
            style={{
              height: 'calc(100% - 12px)',
              top: 6,
              right: 6,
              opacity: focused ? 1 : 0,
              transition: 'opacity 0.1s ease'
            }}
            onClick={this.submit}
          >
            Search
          </button>
        </div>
        <div css={tw`w-full md:w-1/4 md:ml-2 mt-2 md:mt-0`}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={countryOptions.find(country => country.value === store.countryCode)}
            isClearable={false}
            isSearchable
            name="country"
            options={countryOptions}
            onChange={this.onCountryChange}
            formatOptionLabel={this.formatOptionLabel}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: colors.brand,
                primary25: colors['grey-lighter'],
                neutral20: colors['grey-light'],
              },
            })}
          />
        </div>
      </Container>
    );
  }
}

export default view(Header);
